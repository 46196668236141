@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins/breakpoints';


$headertext: 'Proxima Nova', Arial, sans-serif;
$bodytext: 'Baloo Thambi 2', Arial, sans-serif;

/* PCB Arts CI Colors */

//Primary
$navyblue: #000123;
$babyblue: #28CBEA;
$mediumblue: #1C79F9;
$bluepurple: #262461;
$lightgrey: #f5f5f5;

//Secondary

//CI Übersicht machen


//Foregrounds
/*

Es wurde ein online generator für die Auswahl der Farben genutzt (https://coolors.co/app). Dabei wurde die Primärfarbe genommen und in 20% Schritten aufgehellt bzw. verdunkelt. Bei Navyblue wurde die abdunkelung
weggelassen.

*/
$foregroundwhite: rgba(255,255,255,0.84);

$navyblue-superlight: #5C5C78;
$navyblue-light: #2E2E51;

$babyblue-superlight: #76DDF1;
$babyblue-light: #4FD4ED;
$babyblue-dark: #21A7C0;
$babyblue-superdark: #1A8295;

$mediumblue-superlight: #6EAAFB;
$mediumblue-light: #4592FA;
$mediumblue-dark: #1764CC;
$mediumblue-superdark: #124E9F;

$bluepurple-superlight: #74739A;
$bluepurple-light: #4D4B7D;
$bluepurple-dark: #201E50;
$bluepurple-superdark: #19173E;

//Backgrounds
$backgroundwhite: rgba(255,255,255,0.10);


