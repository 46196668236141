.neck {
	position: relative;
	padding: 0;

	flex-grow: 1;

	display: flex;
	justify-content: flex-start;
	align-items: center;

	> .container {
		z-index: 2;
	}
}
.neck__image {
	z-index: -1;
	background-size: cover;
	background-position: 50% 50%;
	background-repeat: no-repeat;

	opacity: 0.5;

	width: 75%;
	max-width: 1300px;
	height: 100vh;
	position: absolute;
	
	right: 0;
	top: -7rem;

	&:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: radial-gradient(ellipse farthest-side at 75% 40%, transparent 0, transparent 50%, $navyblue 90%);
	}
}

.neck__video{
	width: 55%;

	> video {
		position: relative;
		top: 55%;
		height: 70%;
		transform: translateY(-50%);
	}
}

.neck__bubbles {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;	
}
.neck__content {
	position: relative;
	width: 65%;

	.scrolling-bubble {
		top: -80vh;
		left: -40vh;
	}
}
.neck__content__subtitle {
	font-weight: 500;
	font-size: 1.25rem;
	text-transform: uppercase;
}
.neck__content__title {
	font-size: 3.5rem;
	line-height: 1.15;
	margin: .2em 0 .75em 0;	
	position: relative;

	&:before {
		content: "";
		position: absolute;
		
		top: 0;
		left: 0;
		transform: translate(-50%, -40%);
		z-index: -1;

		background-image: url(../img/dots.svg);
		background-size: contain;
		opacity: 0.2;
		width: 10rem;
		height: 10rem;
	}
}

.neck__buttons {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}



@include media-breakpoint-down(lg) {
	.neck__content {
		width: 75%;
	}
}

@include media-breakpoint-down(md) {
	.neck__image {
		width: 90%;
		opacity: 0.4;
	}
	.neck__content {
		width: 75%;
	}
	.neck__content__title {
		font-size: 3.25rem;
	}
}

@include media-breakpoint-down(sm) {
	.neck__image {
		width: 100%;
		&:after {
			background: radial-gradient(ellipse farthest-side at 75% 40%, transparent 0, transparent 50%, $navyblue 100%);
		}
	}

	.neck__content__title {
		font-size: 3rem;
	}
}


@include media-breakpoint-down(xs) {
	.neck__image {
		width: 120%;
	}
	.neck__content__title {
		font-size: 2.25rem;
	}
	.neck__content {
		width: 100%;
	}
}

