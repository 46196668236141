@import 'variables';
@import 'neck';


.box {
    background-image: url("../img/products/edgekit/ready-background.jpg");
    background-position: center;
    background-size: cover;
    width: 100%;
    border-radius: 3rem;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 12px 20px 6px;
}
.box__content {
    padding: 2rem 0 2rem 3rem;
    z-index: 1;
}
.box__content__subtitle {
    font-size: 1.25rem;
    font-weight: 500;
    text-transform: uppercase;
}

.box__content__subtitle__description {
    font-size: 1.15rem;
    font-weight: 400;
    margin:0 0 2em 0;
    line-height: 1.2; 
}

.box__content__action__points > a{
    font-size: 1.25rem;

    &:hover{
        color:white;
    }
}

.box__content__title {
    font-size: 3rem;
    margin: 0;

    &::before {
        display: none;
    }
}

.box__content--small {
    text-align: center;
    padding: 4rem 3rem 3rem 3rem;
    height: 800px;

    display: flex;
    flex-direction: column;
    align-items: center;

    .box__content__subtitle {
        font-size: 1.125rem;
        color: rgba(255, 255, 255, 0.66);
        font-weight: 500;
    }
    .box__content__title {
        font-size: 2rem;
        margin: 2rem 0;
    }

    a {
        margin: 0 .5rem;
        color: $babyblue;
        text-decoration: none;
    }
   
    > img {
        flex-grow: 1;
        object-fit: contain;
        height: 100%;
        margin-top: 3rem;
    }
}

.box__video {
    opacity: 0.5;
    background-color: #000326;
    position: relative;
    height: 100%;

    &::before {
        content: "";
        position: absolute;
        left: -72px;
        top: 0;
        width: 72px;
        height: 100%;
        background-image: linear-gradient(90deg, transparent 0%, #000326 100%);
    }

    > video {
        width: 100%;
        height: 100%;
    }
}

.text-main__title{
    margin:0 auto 1.5em auto;
    font-size:2.5em;
}


@include media-breakpoint-up(lg) {
    .container--wide {
        max-width: 1440px;
    }
}

@include media-breakpoint-down(md) {
    .box__margin__mobile {
        margin-top:3em;
    }

    .box__content {
        padding: 2rem 1.5rem 1.5rem 1.5rem;
    }
    .box__content__title {
        font-size: 2.5rem;
    }
    .box__content--small {
        padding: 2rem 1.5rem 1.5rem 1.5rem;
        height: 640px;

        
        .box__content__title {
            margin: 1rem 0;
        }
        
        > img {
            margin-top: 1rem;
        }
    }
    
    .text-main__subtitle{
        font-size:2.0em;
    }

    .box__video::before {
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
        top: -72px;
        height: 72px;
        background-image: linear-gradient(180deg, transparent 0%, #000326 100%);
    }

}
